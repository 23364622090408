<template>
  <el-form @submit.prevent :model="ruleForm" :rules="rules" ref="ruleForm" :label-position="labelPosition" v-loading.fullscreen.lock="loading">
    <div class="main">
      <!-- <strong class="mb-3"> <i class="fa-solid fa-circle-info"></i> 以下皆為必填資訊（除了 ⊙ 外）</strong> -->
      <div class="side_left">
        <div class="name">
          <label class="red_star">* <span>中文姓名</span></label>
          <el-form-item prop="name">
            <el-input ref="autoFocus" v-model="ruleForm.name" placeholder="真實姓名(送出後即不能修改)" />
          </el-form-item>
        </div>
        <div class="nickName">
          <label class="red_star">* <span>暱稱</span></label>
          <el-form-item prop="nickName">
            <el-input v-model="ruleForm.nickName" placeholder="請輸入暱稱" />
          </el-form-item>
        </div>
        <div class="birthday">
          <label class="red_star">* <span>生日</span></label>
          <el-form-item prop="birthday">
            <el-date-picker v-model="ruleForm.birthday" type="date"></el-date-picker>
          </el-form-item>
        </div>
        <div class="email">
          <label class="red_star">* <span>電子信箱</span></label>
          <el-form-item prop="email">
            <el-input v-model="ruleForm.email" placeholder="請輸入Email" />
          </el-form-item>
        </div>
        <div class="cellPhone">
          <label class="red_star">* <span>聯絡電話</span></label>
          <el-form-item prop="cellPhone">
            <el-input v-model="ruleForm.cellPhone" placeholder="請輸入聯絡電話" />
          </el-form-item>
        </div>
        <div class="localPhone">
          <el-form-item prop="localPhone" label="室內電話(非必填)">
            <el-input v-model="ruleForm.localPhone" placeholder="請輸入室內電話" />
          </el-form-item>
        </div>
        <div class="description">
          <label class="red_star">* <span>相關經驗 (分享您的音樂相關經驗，想成為老師的原因)</span></label>
          <el-form-item prop="description">
            <el-input v-model="ruleForm.description" type="textarea" :autosize="{ minRows: 5 }" placeholder="音樂相關經驗，500字以內" />
          </el-form-item>
        </div>
        <div class="referralCode">
          <el-form-item prop="referralCode" label="推薦人代碼(非必填)">
            <el-input v-model="ruleForm.referralCode" placeholder="請輸入推薦人代碼" />
          </el-form-item>
        </div>
        <div class="instrument">
          <el-form-item prop="instrument">
            <div>
              <label>教學科目(非必填)</label>
              <el-popover placement="top-start" :width="200" trigger="hover" content="如果未找到您的教學科目，可於右下方點選新增科目！">
                <template #reference>
                  <i class="fa-solid fa-circle-exclamation"></i>
                </template>
              </el-popover>
            </div>
            <el-cascader v-model="ruleForm.instrument" :options="$store.state.common.instrumentOptions" :props="props" clearable placeholder="請選擇可教學樂器"></el-cascader>
          </el-form-item>
          <div class="text-end addInstrument" :class="{ active: !isShowAddInstrument }">
            <el-button size="small" v-if="isShowAddInstrument" type="primary" round @click="isShowAddInstrument = false" data-bs-toggle="collapse" data-bs-target="#instrumentAdd" plain>新增科目</el-button>
            <div v-else>
              <el-button size="small" type="primary" round @click="addInstrument" plain>發送科目申請</el-button>
              <el-button size="small" type="primary" round @click="cancelAddInstrument" data-bs-toggle="collapse" data-bs-target="#instrumentAdd" plain>取消</el-button>
            </div>
            <el-form :model="addInstrumentForm" :rules="instrumentRules" ref="addInstrumentForm" class="collapse mt-1" id="instrumentAdd">
              <el-form-item label="類別" prop="instrumentType">
                <el-select v-model="addInstrumentForm.instrumentType" placeholder="請選擇科目類別">
                  <el-option v-for="item in $store.state.common.instrumentTypeOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="名稱" prop="instrumentName">
                <el-input v-model="addInstrumentForm.instrumentName" autocomplete="off" placeholder="請輸入科目名稱"></el-input>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="teachingType">
          <label class="red_star">* <span>教學類型</span></label>
          <el-form-item prop="teachingType">
            <el-cascader v-model="ruleForm.teachingType" :options="$store.state.common.teachingTypeOptions" :props="props" clearable placeholder="請選擇可教學類型"></el-cascader>
          </el-form-item>
        </div>
        <div class="teachingArea">
          <label class="red_star">* <span>教學地點(實體受課地點)</span></label>
          <el-form-item prop="teachingArea">
            <el-cascader v-model="ruleForm.teachingArea" :options="$store.state.common.teachingAreaOptions" :props="props" clearable placeholder="請選擇可教學地點"></el-cascader>
          </el-form-item>
        </div>
      </div>
      <div class="side_right">
        <div class="teachSeniority">
          <label class="red_star">* <span>教學年資</span></label>
          <el-form-item prop="teachSeniority">
            <el-radio-group v-model="ruleForm.teachSeniority">
              <el-radio :label="1" text_color="#bfcbd9">1年以下</el-radio>
              <el-radio :label="2" text_color="#bfcbd9">1~3年</el-radio>
              <el-radio :label="3" text_color="#bfcbd9">3~5年</el-radio>
              <el-radio :label="4" text_color="#bfcbd9">5年以上</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div class="experience">
          <label class="red_star">* <span>經歷</span></label>
          <el-form-item prop="experience">
            <el-input v-model="ruleForm.experience" :rows="10" type="textarea" placeholder="請簡述經歷，500字以內" />
          </el-form-item>
        </div>
        <div class="isHasGroupTeachExperience">
          <label class="red_star">* <span>是否有團班經驗</span></label>
          <el-form-item prop="isHasGroupTeachExperience">
            <el-radio-group v-model="ruleForm.isHasGroupTeachExperience">
              <el-radio :label="true" text_color="#bfcbd9">是</el-radio>
              <el-radio :label="false" text_color="#bfcbd9">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div class="canAdjustTeachWay">
          <label class="red_star">* <span>是否可以配合學生需求調整教學方式</span></label>
          <el-form-item prop="canAdjustTeachWay">
            <el-radio-group v-model="ruleForm.canAdjustTeachWay">
              <el-radio :label="true" text_color="#bfcbd9">是</el-radio>
              <el-radio :label="false" text_color="#bfcbd9">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div class="teachReason">
          <label class="red_star">* <span>為何想從事音樂(藝術)教學</span></label>
          <el-form-item prop="teachReason">
            <el-input v-model="ruleForm.teachReason" :rows="10" type="textarea" placeholder="請簡述為何想從事音樂(藝術)教學，500字以內" />
          </el-form-item>
        </div>
        <div class="expectedAcquisition">
          <label class="red_star">* <span>簡述自己的教學理念並希望在平台獲得什麼</span></label>
          <el-form-item prop="expectedAcquisition">
            <el-input v-model="ruleForm.expectedAcquisition" :rows="10" type="textarea" placeholder="請簡述自己的教學理念並希望在平台獲得什麼，500字以內" />
          </el-form-item>
        </div>
      </div>
    </div>
  </el-form>
  <ConfirmButtonGroup showButton="true" cancelText="全部清空" confirmText="確認送出" @cancel="clearAllData" @confirm="sendData" />
</template>

<script>
import ConfirmButtonGroup from "../../../Common/ConfirmButtonGroup.vue";

export default {
  name: "ApplyForm",
  components: {
    ConfirmButtonGroup,
  },
  data() {
    return {
      isShowAddInstrument: true,
      loading: false,
      labelPosition: "top",
      props: { multiple: true },
      backup: {},
      addInstrumentForm: {
        instrumentType: "",
        instrumentName: "",
      },
      ruleForm: {
        name: "",
        nickName: "",
        birthday: "",
        email: "",
        cellPhone: "",
        localPhone: "",
        description: "",
        referralCode: "",
        instrument: "",
        teachingType: [],
        teachingArea: "",
        teachSeniority: undefined,
        experience: "",
        isHasGroupTeachExperience: undefined,
        canAdjustTeachWay: undefined,
        teachReason: "",
        expectedAcquisition: "",
      },
      rules: {
        name: [
          {
            validator: this.validateEmpty,
            trigger: "change",
          },
        ],
        nickName: [
          {
            validator: this.validateEmpty,
            trigger: "change",
          },
          {
            min: 1,
            max: 30,
            message: "長度請介於1~30個字",
            trigger: "change",
          },
        ],
        birthday: [
          {
            validator: this.validateEmpty,
            trigger: "change",
          },
        ],
        email: [
          {
            validator: this.validateEmpty,
            trigger: "change",
          },
          {
            type: "email",
            message: "請輸入正確信箱格式",
            trigger: ["blur", "change"],
          },
        ],
        cellPhone: [
          {
            validator: this.validateEmpty,
            trigger: "change",
          },
          {
            pattern: /^09[0-9]{8}$/,
            message: "請輸入正確電話格式",
            trigger: "blur",
          },
        ],
        description: [
          {
            validator: this.validateEmpty,
            trigger: "change",
          },
          {
            min: 1,
            max: 500,
            message: "長度請介於1~500個字",
            trigger: "change",
          },
        ],
        referralCode: [
          {
            min: 1,
            max: 30,
            message: "長度請介於1~30個字",
            trigger: "change",
          },
        ],
        teachingType: [
          {
            validator: this.validateEmpty,
            trigger: "change",
          },
        ],
        teachingArea: [
          {
            validator: this.validateEmpty,
            trigger: "change",
          },
        ],
        teachSeniority: [
          {
            validator: this.validateEmpty,
            trigger: "change",
          },
        ],
        experience: [
          {
            validator: this.validateEmpty,
            trigger: "change",
          },
          {
            min: 1,
            max: 500,
            message: "長度請介於1~500個字",
            trigger: "change",
          },
        ],
        isHasGroupTeachExperience: [
          {
            validator: this.validateEmpty,
            trigger: "change",
          },
        ],
        canAdjustTeachWay: [
          {
            validator: this.validateEmpty,
            trigger: "change",
          },
        ],
        teachReason: [
          {
            validator: this.validateEmpty,
            trigger: "change",
          },
          {
            min: 1,
            max: 500,
            message: "長度請介於1~500個字",
            trigger: "change",
          },
        ],
        expectedAcquisition: [
          {
            validator: this.validateEmpty,
            trigger: "change",
          },
          {
            min: 1,
            max: 500,
            message: "長度請介於1~500個字",
            trigger: "change",
          },
        ],
      },
      instrumentRules: {
        instrumentType: [
          {
            validator: this.validateEmpty,
            trigger: "change",
          },
        ],
        instrumentName: [
          {
            validator: this.validateEmpty,
            trigger: "change",
          },
        ],
      },
    };
  },
  async created() {
    await this.getMemberDefault();
  },
  mounted() {
    this.$nextTick(() => this.$refs.autoFocus.focus());
  },
  methods: {
    cancelAddInstrument() {
      const vm = this;
      vm.isShowAddInstrument = true;
      vm.addInstrumentForm = {};
      vm.$refs["addInstrumentForm"].resetFields();
      document.getElementById("instrumentAdd").classList.remove("show");
    },
    async addInstrument() {
      const vm = this;
      vm.$refs["addInstrumentForm"].validate(valid => {
        if (valid) {
          let data = {
            InstrumentType: vm.addInstrumentForm.instrumentType,
            InstrumentName: vm.addInstrumentForm.instrumentName,
          };
          vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/Instruments/apply`, data)
            .then(() => {
              vm.notify("success", "新增科目，申請成功！");
            })
            .catch(err => {
              console.log(err);
              vm.notify("error", "新增科目，申請失敗！");
            })
            .finally(() => {
              document.getElementById("instrumentAdd").classList.remove("show");
              vm.$refs["addInstrumentForm"].resetFields();
              vm.isShowAddInstrument = true;
              vm.addInstrumentForm = {};
            });
        }
      });
    },
    async getMemberDefault() {
      const vm = this;
      vm.fetchAPI("get", `${process.env.VUE_APP_URL_API}/Member/current`)
        .then(res => {
          vm.ruleForm = {
            nickName: res.data.nickName,
            name: res.data.name,
            cellPhone: res.data.cellPhone,
            email: res.data.email,
            birthday: vm.turnBirthdayType(res.data.birthday),
          };
          vm.backup = vm.ruleForm;
        })
        .catch(err => {
          console.log(err);
        });
    },
    sendData(formName) {
      const vm = this;
      vm.$refs[formName].validate(valid => {
        if (valid) {
          let data = {
            Name: vm.ruleForm.name,
            NickName: vm.ruleForm.nickName,
            Birthday: vm.turnBirthdayType(vm.ruleForm.birthday),
            Email: vm.ruleForm.email,
            CellPhone: vm.ruleForm.cellPhone,
            Phone: vm.ruleForm.localPhone,
            Description: vm.ruleForm.description,
            ReferralCode: vm.ruleForm.referralCode,
            Instrument: vm.changeDot(vm.ruleForm.instrument),
            TeachingType: vm.changeDot(vm.ruleForm.teachingType),
            TeachingArea: vm.changeDot(vm.ruleForm.teachingArea),
            TeachSeniority: vm.ruleForm.teachSeniority,
            Experience: vm.ruleForm.experience,
            IsHasGroupTeachExperience: vm.ruleForm.isHasGroupTeachExperience,
            CanAdjustTeachWay: vm.ruleForm.canAdjustTeachWay,
            TeachReason: vm.ruleForm.teachReason,
            ExpectedAcquisition: vm.ruleForm.expectedAcquisition,
          };
          vm.loading = true;
          vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/TeacherApply`, data)
            .then(() => {
              vm.notify("success", "成為老師申請成功！");
              vm.$emit("success");
            })
            .catch(err => {
              console.log(err);
              Object.assign(vm.ruleForm, vm.backup);
              vm.notify("error", "成為老師申請失敗！");
            })
            .finally(() => (vm.loading = false));
        }
      });
    },
    clearAllData() {
      Object.keys(this.ruleForm).forEach(item => {
        this.ruleForm[item] = "";
      });
      this.backup = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  padding: 0 1rem;
  strong {
    display: block;
    color: red;
    margin: 0.5rem 0;
  }
  label {
    span {
      font-size: 1.1rem;
      font-weight: $main_fontWeight;
      color: $text_color;
    }
  }
  .instrument {
    > div:first-child {
      margin-bottom: 0.5rem;
      display: flex;
      align-items: center;
    }
    label {
      font-size: 1.1rem;
      font-weight: $main_fontWeight;
      color: $text_color;
    }
    i {
      margin: 0 0.5rem;
      color: $edit_button_color;
    }
    ::deep .el-input {
      width: 100%;
    }
  }
  :deep .el-cascader {
    width: 100%;
  }
  .addInstrument {
    padding: 0;
    &.active {
      padding: 1rem;
      border: 1px solid $main-gray;
      margin: 1rem 0;
      border-radius: 1rem;
    }
  }
}

@media screen and (min-width: 768px) {
  .main {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 2rem;
    justify-content: space-between;
    strong {
      width: 100%;
    }
    .side_left {
      justify-content: flex-start;
      flex-direction: column;
      width: 40vw;
    }
    .side_right {
      width: 50vw;
      padding-left: 5vw;
      border-left: 2px solid #c9c8c7;
    }
  }
}
</style>
