<template>
  <div v-loading.fullscreen.lock="loading" class="contract_main">
    <h3>請先閱讀合約，需閱讀完畢才能按下確認鍵</h3>
    <div class="contract" @scroll="checkReading($event)">
      <div class="top">
        <p>甲方:學生會員(以下簡稱「甲方」)</p>
        <p>乙方:教師會員(以下簡稱「乙方」)</p>
      </div>
      <ol>
        <li>
          <p>規範主體</p>
          <span>甲、乙雙方經蹦蹦資訊有限公司(以下簡稱「蹦蹦公司」)網站之媒介,而訂立教學服務契約,甲、乙雙方應 秉誠實信用原則履行,蹦蹦公司不予干涉雙方教學服務契約之履行,且不就甲、乙一方債務不履行或任何損害 負擔任何責任。</span>
        </li>
        <li>
          <p>教學服務契約成立與生效</p>
          <ul>
            <li>
              <span>甲、乙雙方經蹦蹦資訊有限公司(以下簡稱「蹦蹦公司」)網站之媒介,而訂立教學服務契約,甲、乙雙方應 秉誠實信用原則履行,蹦蹦公司不予干涉雙方教學服務契約之履行,且不就甲、乙一方債務不履行或任何損害 負擔任何責任。</span>
            </li>
            <li>
              <span>如乙方未於48小時內按下【確認授課】鈕,視同乙方拒絕甲方教學服務之要約。</span>
            </li>
          </ul>
        </li>
        <li>
          <p>付款</p>
          <ul>
            <li>
              <span>甲方於網站上選購課程後須於48小時內以刷卡、超商條碼、ATM轉帳或網路ATM轉帳等平台規範方式完 成付費,如甲方48小時內未完成付費者,蹦蹦公司網站系統將自動取消甲方所選購之課程。</span>
            </li>
            <li>
              <span>甲方付款後,乙方若拒絕或未於48小時內按下【確認授課】鈕,蹦蹦公司將不予扣款並退還學費或點數 予甲方,由蹦蹦公司負擔金流手續費。</span>
            </li>
          </ul>
        </li>
        <li>
          <p>課程資訊與通知</p>
          <ul>
            <li>
              <span>甲方於付款完成並由乙方按下【確認授課】鈕後,蹦蹦公司網站系統將會提供雙方上課之相關資訊,包 含但不限於甲、乙雙方之電子郵件、聯絡資訊以及上課地址等。</span>
            </li>
            <li>
              <span>甲方於付款完成並由乙方按下【確認授課】鈕後,甲、乙雙方得使用蹦蹦公司網站系統之「聊天」功能 進行聯繫。</span>
            </li>
            <li>
              <span>甲、乙雙方約定之教學時間24小時前,蹦蹦公司系統將自動寄發電子郵件及簡訊提醒甲方翌日之課程。</span>
            </li>
          </ul>
        </li>
        <li>
          <p>教學</p>
          <ul>
            <li>
              <span>乙方應提供甲方約定之教學時間、地點及品質之教學服務。</span>
            </li>
            <li>
              <span>甲、乙雙方就課程內容、進度有特別約定,應提供如約定內容之教學服務。</span>
            </li>
            <li>
              <span>乙方為教學目的而媒合、代理學生會員辦理樂器、消耗品、樂譜、教材等之購買、租賃或使用借貸等, 蹦蹦公司不予干涉,乙方應秉誠實信用原則,不得以蹦蹦公司之名義為之,亦不得以顯逾市價之價格辦 理。</span>
            </li>
            <li>
              <span>甲、乙雙方不得以任何名義,規避蹦蹦公司之媒合機制及點數、金額之扣除,而私下進行教學。</span>
            </li>
          </ul>
        </li>
        <li>
          <p>完課確認</p>
          <ul>
            <li>
              <span>甲、乙雙方約定教學時間結束後,蹦蹦公司系統將同時寄出簡訊及電子郵件予甲、乙雙方進行「完課確 認」。甲、乙雙方請根據約定教學時間狀況,點選是否「上完課」、「沒去上課」或「對方沒來」。</span>
            </li>
            <li>
              <span>如一方於約定教學時間結束後72小時內,未確認完課狀態者,蹦蹦公司系統將視為上完課程。</span>
            </li>
          </ul>
        </li>
        <li>
          <p>課程取消、請假及變更</p>
          <p>第1項 預定開課24小時前</p>
          <ul>
            <li>
              <span>每次預定開課24小時前,甲方得無條件向乙方取消該次課程,乙方不得拒絕,但甲方須負擔一定 比例金流手續費(上開比例由該第三方支付業者制訂),於蹦蹦公司扣除金流手續費後,始退還 學費或點數予甲方。</span>
            </li>
            <li>
              <span>每次預定開課24小時前,甲、乙雙方得自行協調、更改教學時間,如協調不成,由提出之一方負 擔上開一定比例之金流手續費,並由蹦蹦公司退還學費或點數予甲方。</span>
            </li>
          </ul>
          <p>第2項 預定開課24小時內</p>
          <ul>
            <li>
              <span>乙方若於每次預定教學時間開始前24小時內,始請假或變更教學時間,應徵得甲方同意,並改約 教學時間,始完成請假手續。若甲方不同意,則該次教學時間所扣點數或金額,無償退還甲方, 並由乙方負擔上開一定比例之金流手續費。</span>
            </li>
            <li>
              <span>除有不可抗力外,距離約定教學時間24小時內,甲方不得請假或變更上課時間。</span>
            </li>
            <li>
              <span>如有不可抗力事由,甲方應自行電聯乙方協調、更改上課時間,若雙方協調不成,學費亦將如期給 予乙方。</span>
            </li>
          </ul>
        </li>
        <li>
          <p>遲到、早退及曠課</p>
          <ul>
            <li>
              <span>甲方於約定之教學時間開始後有遲到、早退或曠課等事由,不得要求本公司退還該次一部或全部之學 費。</span>
            </li>
            <li>
              <span>乙方於約定之教學時間有遲到、早退或曠課等事由,則該次教學時間所扣點數或金額,無償退還甲方, 並由乙方負擔上開一定比例之金流手續費。</span>
            </li>
          </ul>
        </li>
        <li>
          <p>1期多堂課之退費</p>
          <ul>
            <li>
              <span>如甲、乙雙方約定1期多堂課之教學課程,於約定第一堂教學時間前24小時或該期課程尚未終了時,甲、 乙雙方欲終止課程,得使用蹦蹦公司網站系統上之「取消課程」,將整期課程全部取消。</span>
            </li>
            <li>
              <span>甲方為1期多堂課的退費比例說明</span>
            </li>
            <li>
              <span>乙方為1期多堂課的退費比例說明</span>
            </li>
          </ul>
        </li>
        <li>
          <p>準據法與管轄</p>
          <span>甲、乙雙方就教學服務契約內容發生爭執時,以中華民國法律為準據法,並合意由臺灣臺北地方法院為排他管 轄之第一審管轄法院。</span>
        </li>
      </ol>
    </div>
    <div class="confirm_contract">
      <el-checkbox v-model="checked" :disabled="!isReading">閱讀完畢並且同意此合約</el-checkbox>
      <ConfirmButton text="確認" :disabled="!checked" @click="agree"></ConfirmButton>
    </div>
  </div>
</template>

<script>
import ConfirmButton from "../../../Common/CommonElement/ConfirmButton.vue";
export default {
  name: "ServiceContract",
  components: {
    ConfirmButton,
  },
  data() {
    return {
      isReading: false,
      checked: false,
      loading: false,
    };
  },
  methods: {
    agree() {
      this.loading = true;
      setTimeout(() => {
        this.$emit("agree");
      }, 1000);
    },
    checkReading(e) {
      if (e.target.scrollTop + e.target.offsetHeight > e.target.scrollHeight) {
        this.isReading = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.contract_main {
  padding: 1rem;
}
h3 {
  margin-top: 1rem;
  font-size: 1rem;
}
.contract {
  margin-top: 0.5rem;
  border: 1px solid #000;
  height: 60vh;
  overflow-y: auto;
  p {
    margin-bottom: 0.5rem;
  }
  .top {
    text-align: center;
    margin: 1rem 0;
  }
  ol {
    margin: 0 0.5rem;
    li {
      margin-bottom: 1rem;
    }
  }
}
.confirm_contract {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
