<template>
  <div class="apply_progress" v-loading.fullscreen.lock="loading">
    <el-steps :active="active" align-center>
      <el-step title="資料已送出" :description="startTime" status="success" />
      <el-step title="審核中" status="success" />
      <el-step :title="applyResult" :description="endTime" :status="finishStatus" />
    </el-steps>
    <div class="remark" v-if="active === 3 && remark !== ''">備註：{{ remark }}</div>
    <div class="confirm_button">
      <ConfirmButton v-if="active === 3" @click="confirmStatus" :text="statusText"></ConfirmButton>
    </div>
  </div>
</template>

<script>
import ConfirmButton from "../../../Common/CommonElement/ConfirmButton.vue";

export default {
  name: "ApplyProgress",
  components: {
    ConfirmButton,
  },
  props: ["applyData"],
  data() {
    return {
      active: 1,
      status: "",
      startTime: "",
      endTime: "",
      remark: "",
      statusText: "",
      finishStatus: "",
      loading: false,
    };
  },
  mounted() {
    this.setStatus(this.applyData);
  },
  watch: {
    applyData(value) {
      this.setStatus(value);
    },
  },
  methods: {
    confirmStatus() {
      const vm = this;
      if (vm.finishStatus == "success") {
        let data = {
          MemberId: vm.currentUser().id,
          RefreshToken: vm.currentUser().refreshToken,
        };
        vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/Account/token`, data).then(res => {
          vm.$store.dispatch("auth/refreshToken", res);
          // console.log(vm.currentUser());
          if (vm.currentUser().role.includes("Teacher")) {
            vm.notify("success", "恭喜您成為老師！", 2000);
            vm.$router.push("/ChargeMeTribe");
          }
        });
      } else {
        vm.loading = true;
        setTimeout(() => {
          vm.$emit("restart");
        }, 1500);
      }
    },
    setStatus(value) {
      const vm = this;
      vm.finishStatus = "";
      vm.endTime = "";
      let index = value.length - 1;
      vm.startTime = new Date(value[index].requestDateTime).toLocaleString();
      if (value[index].applyStatus === "Requesting") {
        vm.active = 2;
      } else {
        if (value[index].applyStatus === "Failed") {
          vm.statusText = "重新申請";
          vm.finishStatus = "error";
          vm.status = "失敗";
        } else {
          vm.statusText = "確認授課";
          vm.finishStatus = "success";
          vm.status = "成功";
        }
        vm.active = 3;
        vm.remark = vm.applyData[index].remark;
        // console.log(value[index].confirmDateTime);
        vm.endTime = new Date(value[index].confirmDateTime).toLocaleString();
      }
    },
  },
  computed: {
    applyResult() {
      return this.active !== 3 ? "審核結果" : this.status;
    },
  },
};
</script>

<style lang="scss" scoped>
.apply_progress {
  height: 100%;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  .remark,
  .confirm_button {
    flex-grow: 1;
    display: flex;
    align-items: center;
    margin: 2rem auto 0;
  }
  .remark {
    font-size: 2rem;
    font-weight: 900;
    color: rgb(87, 86, 86);
  }
  .el-step__icon.is-text {
    border-color: $main_color;
  }
}
</style>
