<template>
  <div class="teacher input_form">
    <ServiceContract @agree="showApplyForm" v-if="isContractShow"></ServiceContract>
    <ApplyForm @success="showApplyProgress" v-else-if="isApplyFormShow"></ApplyForm>
    <ApplyProgress :applyData="applyData" v-else-if="isApplyProgressShow" @restart="isContractShow = true"></ApplyProgress>
  </div>
</template>

<script>
import ServiceContract from "../../../components/ChargeMeTribe/TeacherCenter/TeacherApply/ServiceContract.vue";
import ApplyForm from "../../../components/ChargeMeTribe/TeacherCenter/TeacherApply/ApplyForm.vue";
import ApplyProgress from "../../../components/ChargeMeTribe/TeacherCenter/TeacherApply/ApplyProgress.vue";

export default {
  name: "TeacherApply",
  components: {
    ApplyForm,
    ServiceContract,
    ApplyProgress,
  },
  data() {
    return {
      isContractShow: false,
      isApplyFormShow: false,
      isApplyProgressShow: false,
      applyData: [],
    };
  },
  async created() {
    await this.getData();
  },
  methods: {
    getData() {
      const vm = this;
      vm.fetchAPI("get", `${process.env.VUE_APP_URL_API}/TeacherApply`)
        .then(res => {
          if (res.data.length === 0) vm.isContractShow = true;
          else {
            this.applyData = res.data;
            vm.isApplyProgressShow = true;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    showApplyForm() {
      this.isContractShow = false;
      this.isApplyFormShow = true;
    },
    showApplyProgress() {
      this.getData();
      this.isApplyFormShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.teacher {
  text-align: start;
  padding: 0 0.5rem 4rem;
}
</style>
